
    import {computed, defineComponent, onMounted, reactive, toRefs,ref} from 'vue';
    import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import api_article from "@/request/apis/api_article";
    import ApiSsy from "@/request/apis/api_ssy";

    interface IState {
        list: any,
        visible: any,
        title: any,
        total: any,
        searches: any
        type: any,
        formState:any,
        treeData:any
    }



    export default defineComponent({
        name: 'salaryRange',
        components: {
            MinusCircleOutlined,
            PlusOutlined,
        },
        setup() {
            let state: IState = reactive({
                visible: false,
                title: '',
                name: '',
                treeData:[],
                list: [],
                total: 0,
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                },
                type: '',
                formState:'' ,
            })
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '标签名称',
                    dataIndex: 'label',
                    key: 'label',
                    align:'center'
                },
                {
                    title: '关键字',
                    dataIndex: 'name',
                    key: 'name',
                    align:'center'
                },
                 {
                    title: '是否必填',
                    dataIndex: 'required',
                    key: 'required',
                    align:'center'
                },
                 {
                    title: '类型',
                    dataIndex: 'type',
                    key: 'type',
                    align:'center'
                },
                {
                    title: '选项',
                    dataIndex: 'xuanxiang',
                    key: 'xuanxiang',
                    align:'center'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width: 150,
                    align:'center'
                },
            ]

            const rules = {
                label: [{required: true, message: "请输入标签名称"},],
                name: [{required: true, message: "请输入标签关键字"},],
                required: [{required: true, message: "请选择是否必填"},],
                type: [{required: true, message: "请选择类型"},],
                options: [{required: true, message: "请输入标签值"},],
            }


            const add = () => {
                state.title = '新增选项'
                state.visible = true
                state.type = 1
                state.formState={
                    "label": "",
	                "name": "",
	                "required": null,
	                "type": null,
	                "options": [
		                {"index": "","value": ""},
	                ],
                }
            }



            const edit = (item) => {
                state.type = 2
                state.title = "编辑选项"
                const val =JSON.parse(JSON.stringify(item))
                state.formState={
                    "id":val.id,
                    "label": val.label,
	                "name": val.name,
	                "required": val.required,
	                "type": Number(val.type),
                }
                state.formState.options = val.options
                state.visible = true
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const getList = async () => {
                const params = {...state.searches}
                const res = await ApiSsy.optionList(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.list
                    state.total = data.count

                }
            }
            const formRef = ref();
            const onSubmit = async () => {
                formRef.value.validate() .then(async() => {
                    if (state.type == 1) {
                        const params = {...state.formState}
                        const res = await ApiSsy.optionAdd(params)
                        if(res.error_code == 0){
                            state.visible = false
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                    } else {
                        const params = {...state.formState}
                        const res = await ApiSsy.optionAdd(params)
                        if(res.error_code == 0){
                            message.success(res.msg)
                            state.visible = false
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                    }
                })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }


            const onDel = async (val) => {
                const params = {id:val.id}
                const res = await ApiSsy.optionDestroy(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        await getList()
                        message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
                }
            }

            const english= ['A','B','C','D','E','F','G','H','I','G','K','L','M','N','O','P','Q','R','S','T']
            const addDomain = () => {
                state.formState.options.push({
                    index: '',
                    value: '',
                });
                state.formState.options.forEach((item,key)=>{
                    item.index = english[key]
                })
            };     
            
            const removeSight = (index:any) => {
                if(state.formState.options.length == 1){
                    message.warning("请至少保留一个")
                }else{
                    state.formState.options.splice(index, 1);
                }
            }

            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                columns,
                open,
                formRef,
                addDomain,
                onSubmit,
                english,
                rules,
                removeSight,
                onDel,
                edit,
                add,
                pagination,
                handleTableChange
            }
        }
    });
